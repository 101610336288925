<template>
  <div class="event_detail">
    <div class="header">
      <div class="title">{{ this.event_title }}</div>
      <div class="date">
        <span class="title">{{ this.$t("event.period") }}</span>
        {{
          this.common.date_short_format(
            this.common.date_parse(this.event_start_date)
          )
        }}
        -
        {{
          this.common.date_short_format(
            this.common.date_parse(this.event_end_date)
          )
        }}
      </div>
    </div>
    <div class="content" v-html="this.event_content"></div>
    <div class="products" v-if="this.product_data.length > 0">
      <h2>{{ this.$t("event.product") }}</h2>
      <ul class="product">
        <li class="no_data" v-if="this.product_data.length <= 0">
          {{ this.$t("product.list.no_data") }}
        </li>
        <li v-for="(value, index) in this.product_data" :key="index">
          <div
            class="image"
            :style="{
              backgroundImage:
                'url(' +
                this.common.get_product_image_url(
                  value.product_id,
                  value.product_images
                ) +
                ')',
            }"
            @click="this.$router.push('/product/detail/' + value.product_id)"
          ></div>
          <div class="desc">
            <div class="seller">{{ value.seller_name }}</div>
            <div class="name">
              <router-link
                :to="{
                  name: 'detail',
                  params: { product_id: value.product_id },
                }"
                >{{ value.product_name }}</router-link
              >
            </div>
            <div class="price">
              <span
                class="percent"
                v-show="value.retail_price - value.sale_price > 0"
                >{{
                  this.common.number_format(
                    ((value.retail_price - value.sale_price) /
                      value.retail_price) *
                      100.0,
                    1
                  )
                }}%</span
              >
              <span class="sale">{{
                this.common.currency_format(value.sale_price, value.unit)
              }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="footer">
      <button
        class="button middle compact sharp"
        @click="this.$router.push('/event')"
      >
        {{ this.$t("common.goto_list") }}
      </button>
    </div>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "EventDetail",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.event_title,
      og: {
        title: this.$t("meta.title") + " :: " + this.event_title,
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      event_id: 0,
      event_title: "",
      event_content: "",
      event_image: "",
      event_start_date: "",
      event_end_date: "",
      event_status: "",
      event_country: "",
      product_data: [],
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.event_id = this.$route.params.event_id;
    this.load_event_data();
  },
  methods: {
    // 이벤트 정보 가져오기
    load_event_data: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/event/get",
          {
            event_id: self.event_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data.data;
          let event_data = data.event;
          let product_data = data.product;

          self.event_title = event_data.event_title;
          self.event_content = event_data.event_content;
          self.event_start_date = event_data.event_start_date;
          self.event_end_date = event_data.event_end_date;
          self.event_image = event_data.event_image;
          self.event_status = event_data.event_status;
          self.event_country = event_data.event_country;

          if (self.event_country != null && self.event_country != "") {
            if (self.event_country != self.common.get_country_code_from_url()) {
              self.$swal.fire({
                text: self.$t("event.error_wrong_country"),
                icon: "error",
              });
              return;
            }
          }

          self.product_data = product_data;
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_event_data
  },
};
</script>

<style scoped>
.event_detail {
  padding: 0;
}
.event_detail > .header {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  padding: 0.8rem 0;
}
.event_detail > .header > .title {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.4rem;
}
.event_detail > .header > .date {
  font-size: 1rem;
  font-weight: 400;
  color: #808080;
}
.event_detail > .header > .date > .title {
  color: #000;
  font-weight: 700;
}
.event_detail > .content {
  padding: 1rem 0;
  margin: 0 0 2rem 0;
}
.event_detail > .content:deep(img) {
  max-width: 100%;
}
.event_detail > .products {
  margin: 0 0 1rem 0;
}
.event_detail > .products > h2 {
  font-size: 1.2rem;
  font-weight: 700;
}

.event_detail > .footer {
  text-align: center;
  margin-bottom: 3rem;
}
</style>
